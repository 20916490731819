import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  Button,
  Divider,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import ModalImageCors from "../../components/ModalImageCors";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import { head } from "lodash";

import LinearWithValueLabel from "../../components/MessageInputCustom/ProgressBarCustom";

import { AuthContext } from "../../context/Auth/AuthContext";
import { useDate } from "../../hooks/useDate";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflow: "hidden",
    borderRadius: 0,
    height: "100%",
    // borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
  },
  containerTitle: {
    padding: "0.75rem 1.875rem",
    height: "4.375rem",
    borderRadius: "0 0.625rem 0 0",
    borderBottom: "0.063rem solid #e0e0e0",
  },
  messageList: {
    position: "relative",
    overflowY: "auto",
    height: "100%",
    ...theme.scrollbarStyles,
  },
  inputArea: {
    position: "relative",
    borderTop: "0.063rem solid #e0e0e0",
    height: "auto",
  },
  input: {
    padding: "20px",
    root: {
      "& label": {
        color: "#757575",
      },
      "& label.Mui-focused": {
        color: "#757575",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#757575",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#e0e0e0",
        },
        "&:hover fieldset": {
          borderColor: "#757575",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#e0e0e0",
        },
      },
    },
  },
  buttonSend: {
    margin: theme.spacing(1),
    backgroundColor: "#523197",
  },
  boxLeft: {
    padding: "10px 10px 5px",
    margin: "10px 10px 10px 50px",
    position: "relative",
    minWidth: 300,
    maxWidth: 471,
    borderRadius: 10,
    borderTopLeftRadius: 0,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    top: -30,
  },
  boxRight: {
    padding: "10px 10px 5px",
    margin: "10px 50px 10px auto",
    position: "relative",
    textAlign: "right",
    minWidth: 300,
    maxWidth: 471,
    borderRadius: 10,
    borderTopRightRadius: 0,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    top: -30,
  },
  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },
  sendMessageIcons: {
    color: "grey",
  },
  uploadInput: {
    display: "none",
  },
  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  tst: {},
}));

const formInputStyle = makeStyles((theme) => ({
  root: {
    "& label": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#757575",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#e0e0e0",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e0e0e0",
      },
    },
  },
}));

const checkMessageMedia = (message, classes) => {
  if (
    message.mediaName.includes(".PNG") ||
    message.mediaName.includes(".JPEG")
  ) {
    return <ModalImageCors imageUrl={message.mediaPath} />;
  }
  if (message.mediaType === "audio") {
    return (
      <audio controls>
        <source src={message.mediaPath} type="audio/ogg"></source>
      </audio>
    );
  }

  if (
    message.mediaName.includes(".mp4") ||
    message.mediaName.includes(".mkv")
  ) {
    return (
      <video
        className={classes.messageMedia}
        src={message.mediaPath}
        controls
      />
    );
  } else {
    return (
      <>
        <div className={classes.downloadMedia}>
          <a
            href={message.mediaPath} // Use the mediaPath directly as the download link
            download // Add the download attribute to indicate it's a download link
          >
            <Button startIcon={<GetApp />} color="primary" variant="outlined">
              Download
            </Button>
          </a>
        </div>
        <Divider />
      </>
    );
  }
};

const MessageBox = ({ item, isCurrentUser }) => {
  const classes = useStyles();
  const { datetimeToClient } = useDate();
  const boxStyles = isCurrentUser ? classes.boxRight : classes.boxLeft;

  return (
    <div
      style={{
        border: 0,
        padding: 0,
        top: 0,
        ...(isCurrentUser
          ? { right: 0, marginRight: 10 }
          : { left: 0, marginLeft: 0 }),
      }}
      className={boxStyles}
    >
      <div
        style={{
          display: "flex",
          alignItems: "start",
          width: "100%",
          gap: "0.5rem",
          ...(isCurrentUser
            ? { justifyContent: "end" }
            : { justifyContent: "start", flexDirection: "row-reverse" }),
        }}
      >
        <Typography style={{ color: "#757575" }} variant="subtitle2">
          {item.sender.name}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "2.375rem",
            height: "2.375rem",
            borderRadius: "3rem",
            backgroundColor: "#d9d9d9",
          }}
        >
          <i
            style={{ color: "white", fontSize: 18, textAlign: "center" }}
            className={"fi-sr-user"}
          ></i>
        </div>
      </div>
      <Box
        key={item.id}
        style={
          isCurrentUser
            ? { backgroundColor: "#523197" }
            : { backgroundColor: "#E0E0E0" }
        }
        className={boxStyles}
      >
        <Typography
          style={isCurrentUser ? { color: "#fff" } : { color: "#757575" }}
          variant="body2"
        >
          {item.message.split("\r\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < item.message.split("\r\n").length - 1 && <br />}
            </React.Fragment>
          ))}
        </Typography>
        {item.mediaPath && checkMessageMedia(item, classes)}
      </Box>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "start",
          position: "relative",
          top: "-2.5rem",
          ...(isCurrentUser
            ? { justifyContent: "start" }
            : { justifyContent: "end", paddingRight: "0.625rem" }),
        }}
      >
        <Typography
          style={{ fontSize: "0.625rem", color: "#757575" }}
          variant="caption"
          display="block"
        >
          {datetimeToClient(item.createdAt)}
        </Typography>
      </div>
    </div>
  );
};

const ChatMessages = ({
  chat,
  messages,
  handleSendMessage: propHandleSendMessage,
  handleLoadMore: propHandleLoadMore,
  scrollToBottomRef,
  pageInfo,
  loading,
}) => {
  const classes = useStyles();
  const [medias, setMedias] = useState(null);
  const [percentLoading, setPercentLoading] = useState(0);
  const { user } = useContext(AuthContext);
  const { datetimeToClient } = useDate();
  const baseRef = useRef();

  const [contentMessage, setContentMessage] = useState("");

  const scrollToBottom = useCallback(() => {
    if (baseRef.current) {
      baseRef.current.scrollIntoView({});
    }
  }, []);

  const unreadMessages = useCallback(
    (chat) => {
      if (chat !== undefined) {
        const currentUser = chat.users.find((u) => u.userId === user.id);
        return currentUser.unreads > 0;
      }
      return 0;
    },
    [user.id]
  );

  useEffect(() => {
    if (unreadMessages(chat) > 0) {
      try {
        api.post(`/chats/${chat.id}/read`, { userId: user.id });
      } catch (err) {
        console.error("Error marking messages as read:", err);
      }
    }
    scrollToBottomRef.current = scrollToBottom;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat, scrollToBottom]);

  const handleScroll = useCallback(
    (e) => {
      const { scrollTop } = e.currentTarget;
      if (!pageInfo.hasMore || loading) return;
      if (scrollTop < 600) {
        propHandleLoadMore();
      }
    },
    [pageInfo.hasMore, loading, propHandleLoadMore]
  );

  const FileInput = ({ handleChangeMedias }) => {
    const classes = useStyles();

    return (
      <>
        <input
          multiple
          type="file"
          id="upload-button"
          className={classes.uploadInput}
          onChange={handleChangeMedias}
        />
        <label htmlFor="upload-button">
          <IconButton aria-label="upload" component="span">
            <i style={{ color: "gray" }} className={"fi-rr-clip"}></i>
            {/* <AttachFileIcon className={classes.sendMessageIcons} /> */}
          </IconButton>
        </label>
      </>
    );
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files[0]) {
      return;
    }

    const selectedMedias = head(e.target.files);
    setMedias(selectedMedias);
  };

  const handleSendMessage = useCallback(() => {
    // If both contentMessage and medias are empty, return early
    if (contentMessage.trim() === "" && !medias) {
      return;
    }

    // Send the message with content and/or media
    propHandleSendMessage(contentMessage, medias);
    setContentMessage("");
    setMedias(null);
  }, [contentMessage, medias, propHandleSendMessage]);

  return (
    <Paper style={{ boxShadow: "none" }} className={classes.mainContainer}>
      <div className={classes.containerTitle}>
        <Typography variant="h6">{chat.title}</Typography>
      </div>
      <div onScroll={handleScroll} className={classes.messageList}>
        {Array.isArray(messages) &&
          messages.map((item, key) => (
            <MessageBox
              key={key}
              item={item}
              isCurrentUser={item.senderId === user.id}
            />
          ))}
        <div ref={baseRef}></div>
      </div>
      <div className={classes.inputArea}>
        <FormControl fullWidth>
          <Input
            value={contentMessage}
            placeholder="Digite sua mensagem"
            onKeyUp={(e) => {
              if (e.key === "Enter" && contentMessage.trim() !== "") {
                // If Shift key is pressed, add a line break; otherwise, send the message
                if (!e.shiftKey) {
                  handleSendMessage(contentMessage);
                } else {
                  setContentMessage((prevContent) => prevContent);
                }
              }
            }}
            onChange={(e) => setContentMessage(e.target.value)}
            className={classes.input}
            endAdornment={
              <InputAdornment position="end">
                <FileInput handleChangeMedias={handleChangeMedias} />
                {loading ? (
                  <div>
                    <LinearWithValueLabel progress={percentLoading} />
                  </div>
                ) : (
                  <span>{medias?.name}</span>
                )}
                <IconButton
                  onClick={handleSendMessage}
                  className={classes.buttonSend}
                >
                  <i
                    style={{ color: "white", display: "flex" }}
                    className={"fi-rr-paper-plane"}
                  ></i>
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
    </Paper>
  );
};

export default ChatMessages;
