import React, { useState, useEffect } from "react";

import { makeStyles, Paper } from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import useHelps from "../../hooks/useHelps";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Card } from "../../components/Card";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    display: "flex",
    flexDirection: "column",
    marginTop: "0.625rem",
    gap: "0.625rem",
    width: "100%",
    minHeight: "200px",
    ...theme.scrollbarStyles,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  container: {
    alignItems: "center",
  },
}));

const accordionStyle = makeStyles((theme) => ({
  root: {
    "&:before": {
      content: "none",
      opacity: 0,
    },
  },
}));

// const fakeRecords = [
//   {
//     title: "Como criar uma nova campanha",
//     description: "Neste vídeo, mostramos como criar uma nova campanha",
//     video: "dQw4w9WgXcQ",
//   },
//   {
//     title: "Como criar uma nova campanha",
//     description: "Neste vídeo, mostramos como criar uma nova campanha",
//     video: "dQw4w9WgXcQ",
//   },
//   {
//     title: "Como criar uma nova campanha",
//     description: "Neste vídeo, mostramos como criar uma nova campanha",
//     video: "dQw4w9WgXcQ",
//   },
// ];

const Helps = () => {
  const classes = useStyles();
  const accordionClass = accordionStyle();

  const [records, setRecords] = useState([]);
  const { list } = useHelps();

  useEffect(() => {
    async function fetchData() {
      const helps = await list();
      setRecords(helps);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderVideo = (record) => {
    const url = `https://www.youtube.com/embed/${record.video}`;
    return (
      <iframe
        style={{ width: 565, height: 500 }}
        src={url}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  };

  const renderHelps = () => {
    return (
      <>
        {records?.map((record, key) => (
          <Accordion
            style={{
              boxShadow: "none",
              border: "0.063rem solid #e0e0e0",
              borderRadius: "0.625rem",
            }}
            classes={accordionClass}
            key={key}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {record.title}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {record.description}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{renderVideo(record)}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    );
  };

  return (
    <MainContainer alignItems="center">
      <Card title="CENTRAL DE AJUDA">
        <Paper style={{ boxShadow: "none" }} className={classes.mainPaper}>
          {renderHelps()}
        </Paper>
      </Card>
    </MainContainer>
  );
};

export default Helps;
