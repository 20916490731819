import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { Card } from "../../components/Card";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px",
  },
  btn: {
    color: "#fff",
    backgroundColor: "#9747FF",
    "&:hover": {
      backgroundColor: "#523197",
    },
  },
}));

const formInputStyle = makeStyles((theme)=> ({
  root: {
    '& label': {
      color: '#757575',
    },
    '& label.Mui-focused': {
      color: '#757575',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#757575',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#e0e0e0',
      },
      '&:hover fieldset': {
        borderColor: '#757575',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#e0e0e0',
      },
    },
  },
}));

const App = () => {
  const { form, container, btn } = useStyles();
  const classes = formInputStyle()

  const [sender, setSender] = useState(""); // Altere para uma string
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [emails, setEmails] = useState([]);

  const handleSendEmail = async () => {
    if (subject && message) {
      // Divide a string de sender em um array de endereços de e-mail
      const senderEmails = sender.split(",").map((email) => email.trim());

      try {
        for (const email of senderEmails) {
          const response = await api.post(
            `${process.env.REACT_APP_BACKEND_URL}/enviar-email`,
            {
              email,
              tokenSenha: "",
              assunto: subject,
              mensagem: message,
            }
          );

          console.log("Resposta da API para", email, ":", response.data);

          // Adicione o e-mail à lista de e-mails enviados
          setEmails([...emails, { sender: email, subject, message }]);
        }

        // Limpa os campos após o envio
        setSender("");
        setSubject("");
        setMessage("");
      } catch (error) {
        console.error("Erro na API:", error);
      }
    }
  };

  return (
    <Container component="main" maxWidth="lg" className={container}>
      <Card title="DISPARO DE EMAIL">
        <form className={form}>
          <TextField
            label="Destinatario (separe por vírgula)"
            value={sender}
            onChange={(e) => setSender(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            classes={classes}
            required
          />
          <TextField
            label="Assunto"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            classes={classes}
            required
          />
          <TextField
            label="Mensagem"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            classes={classes}
            required
          />
          <Button
            style={{ textTransform: "none" }}
            className={btn}
            onClick={handleSendEmail}
            fullWidth
          >
            Enviar
          </Button>
        </form>
      </Card>
    </Container>
  );
};

export default App;
