import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { CSSTransition } from "react-transition-group";
import api from "../../services/api";
import { Card } from "../../components/Card/index";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "1.25rem 0 0 0",
    gap: "0.625rem",
  },
  btn: {
    color: "#fff",
    backgroundColor: "#9747FF",
    "&:hover": {
      backgroundColor: "#523197",
    },
  },
  paper: {
    position: "relative",
    padding: "10px",
    maxWidth: 800,
    width: "100%",
    backgroundColor: "#f1f1f1",
    marginBottom: "16px",
  },
  email: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    flexDirection: "column",
    borderRadius: "0.313rem 0.313rem 0 0",
    border: "0.063rem solid #e0e0e0",
  },
  hideBtn: {
    display: "none",
  },
  pagination: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  // Classes para animações de fade
  "email-fade-enter": {
    opacity: 0,
  },
  "email-fade-enter-active": {
    opacity: 1,
    transition: "opacity 300ms ease-in",
  },
  "email-fade-exit": {
    opacity: 1,
  },
  "email-fade-exit-active": {
    opacity: 0,
    transition: "opacity 300ms ease-out",
  },
}));

// const fakeEmails = [
//   {
//     sender: "fulano",
//     subject: "slá que isso",
//     message: "hauhuahuaha",
//     scheduled: true,
//     sendAt: new Date(),
//     companyId: 15,
//     company: {
//       name: "ahkjdhaksd",
//       email: "ekaehae@jalksjd.com",
//       document: "sadasd1321",
//     },
//   },
//   {
//     sender: "zé da manga",
//     subject: "abrabrabrbar",
//     message: "hihihihihiihi",
//     scheduled: true,
//     sendAt: new Date(),
//     companyId: 15,
//     company: {
//       name: "ahkjdhaksd",
//       email: "ekaehae@jalksjd.com",
//       document: "sadasd1321",
//     },
//   },
// ];

const EmailList = () => {
  const classes = useStyles();
  const [emails, setEmails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const emailsPerPage = 5; // Quantidade de emails por página

  useEffect(() => {
    // Carregar os emails enviados ao montar o componente
    loadEmails();
  }, []);

  const loadEmails = async () => {
    try {
      // Fazer uma requisição à API para obter os emails enviados
      const response = await api.get(
        `${process.env.REACT_APP_BACKEND_URL}/listar-emails-agendados`
      );
      setEmails(response.data);
    } catch (error) {
      console.error("Erro ao carregar emails:", error);
    }
  };

  const openEmail = (i, email) => {
    // Define o email selecionado para abrir
    setSelectedIndex(i);
    setSelectedEmail(email);
  };

  const closeEmail = () => {
    // Fecha o email selecionado
    setSelectedIndex(null);
    setSelectedEmail(null);
  };

  const indexOfLastEmail = currentPage * emailsPerPage;
  const indexOfFirstEmail = indexOfLastEmail - emailsPerPage;
  const currentEmails = emails.slice(indexOfFirstEmail, indexOfLastEmail);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Container component="main" maxWidth="lg" className={classes.container}>
      <Card title="AGENDADOS AGUARDANDO ENVIO">
        <div className={classes.cardContent}>
          {currentEmails.map((email, i) => (
            <div
              key={email.id}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className={classes.email}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>
                    <Typography variant="h6">{email.subject}</Typography>
                    <Typography variant="subtitle1">
                      Destinatário: {email.sender}
                    </Typography>
                    <Typography variant="body1">
                      {email.message.length > 100
                        ? email.message.substring(0, 100) + "..."
                        : email.message}
                    </Typography>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={`${classes.btn} ${
                      selectedIndex === i && classes.hideBtn
                    }`}
                    onClick={() => openEmail(i, email)}
                  >
                    Abrir Email
                  </Button>
                </div>
              </div>
              {selectedIndex === i && (
                <CSSTransition
                  in={selectedEmail !== null}
                  timeout={300}
                  classNames="email-fade"
                  unmountOnExit
                >
                  <Paper
                    style={{ boxShadow: "none" }}
                    className={classes.paper}
                  >
                    <Typography variant="h6">Email Completo</Typography>
                    <Typography variant="h6">
                      {selectedEmail?.subject}
                    </Typography>
                    <Typography variant="subtitle1">
                      Destinatário: {selectedEmail?.sender}
                    </Typography>
                    <Typography variant="body1">
                      {selectedEmail?.message}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.btn}
                        onClick={closeEmail}
                      >
                        Fechar Email
                      </Button>
                    </div>
                  </Paper>
                </CSSTransition>
              )}
            </div>
          ))}
          <div className={classes.pagination}>
            {Array.from(
              { length: Math.ceil(emails.length / emailsPerPage) },
              (_, i) => (
                <Button
                  key={i}
                  variant="outlined"
                  onClick={() => paginate(i + 1)}
                >
                  {i + 1}
                </Button>
              )
            )}
          </div>
        </div>
      </Card>
    </Container>
  );
};

export default EmailList;
