import React from "react"; // { useContext }
// import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import { Card } from "../../components/Card";
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  // FormControl,
  Grid,
  // InputLabel,
  // MenuItem,
  Paper,
  // Select,
  TextField,
} from "@material-ui/core";

import Title from "./Title";

const formInputStyle = makeStyles((theme) => ({
  root: {
    "& label": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#757575",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#e0e0e0",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e0e0e0",
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  btn: {
    color: "#fff",
    backgroundColor: "#9747FF",
    "&:hover": {
      backgroundColor: "#523197",
    },
  },
}));

const Filters = ({
  classes,
  setDateStartTicket,
  setDateEndTicket,
  dateStartTicket,
  dateEndTicket,
  setQueueTicket,
  queueTicket,
}) => {
  // const { user } = useContext(AuthContext);
  const { btn } = useStyles();
  const formInput = formInputStyle();

  const [
    queues,
    // setQueues
  ] = React.useState(queueTicket);
  const [dateStart, setDateStart] = React.useState(dateStartTicket);
  const [dateEnd, setDateEnd] = React.useState(dateEndTicket);

  return (
    <Card fullWidth title={i18n.t("dashboard.title.filter").toUpperCase()}>
      <Grid item xs={12}>
        <Paper
          //   className={classes.customFixedHeightPaperLg}
          style={{
            boxShadow: "none",
            padding: 0,
            overflowY: "none",
            border: "none",
            marginTop: "0.625rem",
          }}
          elevation={6}
        >
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="queue-label">
                                Departamentos
                            </InputLabel>
                            <Select
                                labelId="queue-label"
                                id="queue-select"
                                defaultValue={queueTicket}
                                label="Departamentos"
                                onChange={(e) => setQueues(e.target.value)}
                            >
                                <MenuItem value={false}>
                                    Todos os Departamentos
                                </MenuItem>
                                {user.queues.map((queue) => (
                                    <MenuItem key={queue.id} value={queue.id}>
                                        {queue.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid> */}

            <Grid item xs={12} sm={6} md={5}>
              <TextField
                classes={formInput}
                fullWidth
                name="dateStart"
                label={i18n.t("dashboard.title.startDate")}
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                defaultValue={dateStart}
                onChange={(e) => setDateStart(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                classes={formInput}
                fullWidth
                name="dateEnd"
                label={i18n.t("dashboard.title.endDate")}
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                defaultValue={dateEnd}
                onChange={(e) => setDateEnd(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={btn}
                onClick={() => {
                  setQueueTicket(queues);
                  setDateStartTicket(dateStart);
                  setDateEndTicket(dateEnd);
                }}
              >
                {i18n.t("dashboard.title.filter")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Card>
  );
};

export default Filters;
