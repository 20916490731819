import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Collapse, List } from "@material-ui/core";

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { socketConnection } from "../services/socket";
import { isArray } from "lodash";
import api from "../services/api";
import toastError from "../errors/toastError";
import usePlans from "../hooks/usePlans";
import useVersion from "../hooks/useVersion";

import { PlugZap } from "lucide-react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  ListSubheader: {
    height: 26,
    marginTop: "-15px",
    marginBottom: "20px",
  },
  listItem: {
    borderRadius: 10,
    margin: "5px 0",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  subMenu: {
    paddingLeft: 15,
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  collapseItems: {
    display: "flex",
    borderRadius: "0.313rem",
    marginBottom: "0.06rem",
    padding: "0 1rem",
    fontSize: "0.813rem",
    color: theme.palette.type === "dark" ? "#fff" : "#757575",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#3F1D85",
      "& $icon": {
        color: "#fff",
      },
    },
  },
  collapsedItemDynamicPadding: {
    padding: "0 1rem 0 2.125rem",
  },
  collapsedItemSelected: {
    backgroundColor: "#523197",
    color: "#fff",
    "& $icon": {
      color: "#fff",
    },
  },
  logoutButton: {
    border: "1px solid" + theme.palette.error.main,
    borderRadius: 5,
    marginTop: 10,
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      color: "#fff",
      "& $logoutIcon": {
        color: "#fff",
      },
    },
  },
  logoutIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.125rem",
    color: theme.palette.error.main,
  },
  menuItems: {
    display: "flex",
    borderRadius: "0.313rem",
    fontSize: "0.813rem",
    lineHeight: "1.016rem",
    marginBottom: "0.06rem",
    color: theme.palette.type === "dark" ? "#fff" : "#757575",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#3F1D85",
      "& $icon": {
        color: "#fff",
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.125rem",
    color: theme.palette.type === "dark" ? "#fff" : "#757575",
  },
  selectedItem: {
    backgroundColor: "#523197",
    color: "#fff",
    "& $icon": {
      color: "#fff",
    },
  },
  iconInHover: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.125rem",
    color: "#fff",
  },
}));

function ListItemLink({ icon, primary, to, className, boldIcon }) {
  const classes = useStyles();

  const location = useLocation();
  const currentPath = location.pathname;
  const isActive = currentPath === to;

  const listItemClassName = isActive
    ? `${classes.menuItems} ${classes.selectedItem}`
    : classes.menuItems;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li className={listItemClassName}>
      <ListItem dense button component={renderLink} className={className}>
        {icon ? (
          // <ListItemIcon style={{ minWidth: "3rem" }}>{icon}</ListItemIcon>
          <ListItemIcon style={{ minWidth: "3rem" }}>
            <i className={`${isActive ? boldIcon : icon} ${classes.icon}`}></i>
          </ListItemIcon>
        ) : null}

        <ListItemText
          style={{ fontSize: "0.813rem", lineHeight: "1.016rem" }}
          primary={primary}
        />
      </ListItem>
    </li>
  );
}

const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
};

const MainListItems = ({ drawerClose, collapsed, drawerOpen }) => {
  const classes = useStyles();
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user, handleLogout } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  // novas features
  const [showSchedules, setShowSchedules] = useState(false);
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [showExternalApi, setShowExternalApi] = useState(false);
  const [openKanbanSubmenu, setOpenKanbanSubmenu] = useState(false);
  const [openEmailSubmenu, setOpenEmailSubmenu] = useState(false);

  const history = useHistory();

  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);
  const [version, setVersion] = useState(false);

  const { getPlanCompany } = usePlans();

  const { getVersion } = useVersion();

  const handleClickLogout = () => {
    //handleCloseMenu();
    handleLogout();
  };

  useEffect(() => {
    async function fetchVersion() {
      const _version = await getVersion();
      setVersion(_version.version);
    }
    fetchVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    async function fetchData() {
      const companyId = localStorage.getItem("companyId");
      const planConfigs = await getPlanCompany(undefined, companyId);
      setShowCampaigns(planConfigs.plan.useCampaigns);
      setShowSchedules(planConfigs.plan.useSchedules);
      setShowInternalChat(planConfigs.plan.useInternalChat);
      setShowExternalApi(planConfigs.plan.useExternalApi);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  // useEffect(() => {
  //   if (localStorage.getItem("cshow")) {
  //     setShowCampaigns(true);
  //   }
  // }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  const location = useLocation();
  const currentPath = location.pathname;
  // const isActive = currentPath === to;

  // const listItemClassName = isActive
  //   ? `${classes.menuItems} ${classes.selectedItem}`
  //   : classes.menuItems;

  return (
    <div onClick={drawerClose}>
      <Can
        role={user.profile}
        perform="dashboard:view"
        yes={() => (
          <ListItemLink
            to="/"
            primary="Dashboard"
            icon="fi-rr-chart-pie-alt"
            boldIcon="fi fi-sr-chart-pie-alt"
          />
        )}
      />

      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon="fi-rr-comment"
        boldIcon="fi fi-sr-comment"
      />

      <ListItem
        className={classes.collapseItems}
        button
        onClick={() => setOpenEmailSubmenu((prev) => !prev)}
      >
        <ListItemIcon style={{ minWidth: "3rem" }}>
          <i className={`fi-rr-envelope ${classes.icon}`}></i>
        </ListItemIcon>
        <ListItemText primary={i18n.t("Email")} />
        {openEmailSubmenu ? (
          <i className={`fi-rr-angle-small-up ${classes.icon}`}></i>
        ) : (
          <i className={`fi-rr-angle-small-down ${classes.icon}`}></i>
        )}
      </ListItem>
      <Collapse in={openEmailSubmenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            className={`${classes.collapseItems} ${
              drawerOpen ? classes.collapsedItemDynamicPadding : ""
            } ${currentPath === "/Email" && classes.collapsedItemSelected}`}
            onClick={() => history.push("/Email")}
            button
          >
            <ListItemIcon>
              {currentPath === "/Email" ? (
                <i className={`fi fi-sr-envelope-plus ${classes.icon}`}></i>
              ) : (
                <i className={`fi-rr-envelope-plus ${classes.icon}`}></i>
              )}
            </ListItemIcon>
            <ListItemText primary={i18n.t("Enviar")} />
          </ListItem>

          <ListItem
            className={`${classes.collapseItems} ${
              drawerOpen ? classes.collapsedItemDynamicPadding : ""
            } ${currentPath === "/EmailLis" && classes.collapsedItemSelected}`}
            onClick={() => history.push("/EmailLis")}
            button
          >
            <ListItemIcon>
              {currentPath === "/EmailLis" ? (
                <i className={`fi fi-sr-envelope-marker ${classes.icon}`}></i>
              ) : (
                <i className={`fi-rr-envelope-marker ${classes.icon}`}></i>
              )}
            </ListItemIcon>
            <ListItemText primary={i18n.t("Enviados")} />
          </ListItem>

          <ListItem
            className={`${classes.collapseItems} ${
              drawerOpen ? classes.collapsedItemDynamicPadding : ""
            } ${
              currentPath === "/EmailScheduler" && classes.collapsedItemSelected
            }`}
            onClick={() => history.push("/EmailScheduler")}
            button
          >
            <ListItemIcon>
              {currentPath === "/EmailScheduler" ? (
                <i className={`fi fi-sr-clock ${classes.icon}`}></i>
              ) : (
                <i className={`fi-rr-clock ${classes.icon}`}></i>
              )}
            </ListItemIcon>
            <ListItemText primary={i18n.t("Agendar")} />
          </ListItem>

          <ListItem
            className={`${classes.collapseItems} ${
              drawerOpen ? classes.collapsedItemDynamicPadding : ""
            } ${
              currentPath === "/EmailsAgendado" && classes.collapsedItemSelected
            }`}
            onClick={() => history.push("/EmailsAgendado")}
            button
          >
            <ListItemIcon>
              {currentPath === "/EmailsAgendado" ? (
                <i className={`fi fi-sr-clock ${classes.icon}`}></i>
              ) : (
                <i className={`fi-rr-clock ${classes.icon}`}></i>
              )}
            </ListItemIcon>
            <ListItemText primary={i18n.t("Agendados")} />
          </ListItem>
        </List>
      </Collapse>

      <ListItem
        className={classes.collapseItems}
        button
        onClick={() => setOpenKanbanSubmenu((prev) => !prev)}
      >
        <ListItemIcon style={{ minWidth: "3rem" }}>
          <i className={`fi-rr-chart-histogram ${classes.icon}`}></i>
        </ListItemIcon>
        <ListItemText primary={i18n.t("Kanban")} />
        {openKanbanSubmenu ? (
          <i className={`fi-rr-angle-small-up ${classes.icon}`}></i>
        ) : (
          <i className={`fi-rr-angle-small-down ${classes.icon}`}></i>
        )}
      </ListItem>
      <Collapse in={openKanbanSubmenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            className={`${classes.collapseItems} ${
              drawerOpen ? classes.collapsedItemDynamicPadding : ""
            } ${currentPath === "/kanban" && classes.collapsedItemSelected}`}
            onClick={() => history.push("/kanban")}
            button
          >
            <ListItemIcon>
              {currentPath === "/kanban" ? (
                <i className={`fi fi-sr-settings-sliders ${classes.icon}`}></i>
              ) : (
                <i className={`fi-rr-settings-sliders ${classes.icon}`}></i>
              )}
            </ListItemIcon>
            <ListItemText primary={i18n.t("Painel")} />
          </ListItem>

          <ListItem
            className={`${classes.collapseItems} ${
              drawerOpen ? classes.collapsedItemDynamicPadding : ""
            } ${
              currentPath === "/tagsKanban" && classes.collapsedItemSelected
            }`}
            onClick={() => history.push("/tagsKanban")}
            button
          >
            <ListItemIcon>
              {currentPath === "/tagsKanban" ? (
                <i className={`fi fi-sr-bookmark ${classes.icon}`}></i>
              ) : (
                <i className={`fi-rr-bookmark ${classes.icon}`}></i>
              )}
            </ListItemIcon>
            <ListItemText primary={i18n.t("Tags")} />
          </ListItem>

          {/* <ListItem onClick={() => history.push("/campanhas")} button>
            <ListItemIcon>
            <EventAvailableIcon />
            </ListItemIcon>
            <ListItemText primary={i18n.t("Em Andamento")} />
            </ListItem> */}
        </List>
      </Collapse>

      <ListItemLink
        to="/quick-messages"
        primary={i18n.t("mainDrawer.listItems.quickMessages")}
        icon="fi fi-rr-bolt"
        boldIcon="fi fi-sr-bolt"
      />

      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon="fi-rr-user"
        boldIcon="fi fi-sr-user"
      />

      <ListItemLink
        to="/todolist"
        primary={i18n.t("Tarefas")}
        icon="fi-rr-list-check"
        boldIcon="fi fi-sr-list-check"
      />

{/*       
        <ListItemLink
          to="/Calendario"
          primary={i18n.t("Calendario")}
          icon={<CalendarToday />}
        />
        */}

      {showSchedules && (
        <>
          <ListItemLink
            to="/schedules"
            primary={i18n.t("mainDrawer.listItems.schedules")}
            icon="fi-rr-calendar"
            boldIcon="fi fi-sr-calendar"
          />
        </>
      )}

      <ListItemLink
        to="/birthdays"
        primary={"Aniversariantes"}
        icon="fi fi-rr-cake-birthday"
        boldIcon="fi fi-sr-cake-birthday"
      />

      <ListItemLink
        to="/tags"
        primary={i18n.t("mainDrawer.listItems.tags")}
        icon="fi-rr-bookmark"
        boldIcon="fi fi-sr-bookmark"
      />

      {showInternalChat && (
        <>
          <ListItemLink
            to="/chats"
            primary={i18n.t("mainDrawer.listItems.chats")}
            icon="fi-rr-comments"
            boldIcon="fi fi-sr-comments"
          />
        </>
      )}

      {/*<ListItemLink
        to="/ChatGPT"
        primary={i18n.t("ChatGPT")}
        icon={<LiveHelpIcon />}
          />*/}

      <ListItemLink
        to="/helps"
        primary={i18n.t("mainDrawer.listItems.helps")}
        icon="fi-rr-interrogation"
        boldIcon="fi fi-sr-interrogation"
      />

      <Can
        style={{ marginTop: "5px" }}
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider />
            {/*<ListSubheader inset>
              {i18n.t("mainDrawer.listItems.administration")}
            </ListSubheader>*/}
            {showCampaigns && (
              <>
                <ListItem
                  className={classes.collapseItems}
                  // dense
                  button
                  onClick={() => setOpenCampaignSubmenu((prev) => !prev)}
                >
                  <ListItemIcon style={{ minWidth: "3rem" }}>
                    <i className={`fi-sr-chat-arrow-grow ${classes.icon}`}></i>
                  </ListItemIcon>
                  <ListItemText
                    primary={i18n.t("mainDrawer.listItems.campaigns")}
                  />
                  {openCampaignSubmenu ? (
                    <i className={`fi-rr-angle-small-up ${classes.icon}`}></i>
                  ) : (
                    <i className={`fi-rr-angle-small-down ${classes.icon}`}></i>
                  )}
                </ListItem>
                <Collapse in={openCampaignSubmenu} timeout="auto" unmountOnExit>
                  <List dense component="div" disablePadding>
                    <ListItem
                      className={`${classes.collapseItems} ${
                        drawerOpen ? classes.collapsedItemDynamicPadding : ""
                      } ${
                        currentPath === "/campaigns" &&
                        classes.collapsedItemSelected
                      }`}
                      onClick={() => history.push("/campaigns")}
                      button
                    >
                      <ListItemIcon>
                        {currentPath === "/campaigns" ? (
                          <i className={`fi fi-sr-list ${classes.icon}`}></i>
                        ) : (
                          <i className={`fi-rr-list ${classes.icon}`}></i>
                        )}
                      </ListItemIcon>
                      <ListItemText primary={i18n.t("Listagem")} />
                    </ListItem>

                    <ListItem
                      className={`${classes.collapseItems} ${
                        drawerOpen ? classes.collapsedItemDynamicPadding : ""
                      } ${
                        currentPath === "/contact-lists" &&
                        classes.collapsedItemSelected
                      }`}
                      onClick={() => history.push("/contact-lists")}
                      button
                    >
                      <ListItemIcon>
                        {currentPath === "/contact-lists" ? (
                          <i
                            className={`fi fi-sr-comment-user ${classes.icon}`}
                          ></i>
                        ) : (
                          <i
                            className={`fi-rr-comment-user ${classes.icon}`}
                          ></i>
                        )}
                      </ListItemIcon>
                      <ListItemText primary={i18n.t("Listas de Contatos")} />
                    </ListItem>

                    <ListItem
                      className={`${classes.collapseItems} ${
                        drawerOpen ? classes.collapsedItemDynamicPadding : ""
                      } ${
                        currentPath === "/campaigns-config" &&
                        classes.collapsedItemSelected
                      }`}
                      onClick={() => history.push("/campaigns-config")}
                      button
                    >
                      <ListItemIcon>
                        {currentPath === "/campaigns-config" ? (
                          <i
                            className={`fi fi-sr-settings ${classes.icon}`}
                          ></i>
                        ) : (
                          <i className={`fi-rr-settings ${classes.icon}`}></i>
                        )}
                      </ListItemIcon>
                      <ListItemText primary={i18n.t("Configurações")} />
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}

            {user.super && (
              <ListItemLink
                to="/announcements"
                primary={i18n.t("mainDrawer.listItems.annoucements")}
                icon="fi-rr-bookmark"
                boldIcon="fi fi-sr-bookmark"
              />
            )}
            {/* user.super && (
              <ListItemLink
                to="/campanhaAvancada"
                primary={i18n.t("Campanha Avancada")}
                icon={<PlaylistAddCheckIcon />}
              />
            ) */}

            {showExternalApi && (
              <>
                <ListItemLink
                  to="/messages-api"
                  primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                  icon="fi-ts-api"
                  boldIcon="fi-ss-api"
                />
              </>
            )}

            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon="fi-rr-user-add"
              boldIcon="fi fi-sr-user-add"
            />
            <ListItemLink
              to="/queues"
              primary={i18n.t("mainDrawer.listItems.queues")}
              icon="fi-rr-chart-tree"
              boldIcon="fi fi-sr-chart-tree"
            />
            <ListItemLink
              to="/connections"
              primary={i18n.t("mainDrawer.listItems.connections")}
              icon="fi-rr-chart-connected"
              boldIcon="fi fi-sr-chart-connected"
            />

            {/*<ListItemLink
              to="/ratings"
              primary={i18n.t("mainDrawer.listItems.ratings")}
              icon={<StarOutlineIcon />}
            /> 

            {
              <ListItemLink
                to="/integrations"
                primary={"Integrações"}
                icon={<AddToQueueRounded />}
              />
            }*/}
            <ListItemLink
              to="/financeiro"
              primary={i18n.t("mainDrawer.listItems.financeiro")}
              icon="fi-rr-bank"
              boldIcon="fi fi-sr-bank"
            />
            <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon="fi-rr-settings"
              boldIcon="fi fi-sr-settings"
            />

            {/* {user.super && (
              <ListSubheader inset>
                {i18n.t("mainDrawer.listItems.administration")}
              </ListSubheader>
            )} */}

            {user.super && (
              <ListItemLink
                to="/companies"
                primary={i18n.t("mainDrawer.listItems.companies")}
                icon="fi-rr-id-badge"
                boldIcon="fi fi-sr-id-badge"
              />
            )}
            {/*  REMOVIDO POR NÃO TER NA NOVA VERSÃO DO FIGMA */}
            {/* {!collapsed && (
              <React.Fragment>
                <Divider /> */}
            {/*
              // IMAGEM NO MENU
              <Hidden only={['sm', 'xs']}>
                <img style={{ width: "100%", padding: "10px" }} src={logo} alt="image" />            
              </Hidden> 
              */}
            {/* <Typography
                  style={{
                    fontSize: "12px",
                    padding: "10px",
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                >
                  V:{version}
                </Typography>
              </React.Fragment>
            )} */}
          </>
        )}
      />
      <Divider />
      <li>
        <ListItem
          button
          dense
          onClick={handleClickLogout}
          className={classes.logoutButton}
        >
          <ListItemIcon style={{ minWidth: "3rem" }}>
            <i className={`fi-rr-map-marker-cross ${classes.logoutIcon}`}></i>
          </ListItemIcon>
          <ListItemText primary={i18n.t("Sair")} />
        </ListItem>
      </li>
    </div>
  );
};

export default MainListItems;
